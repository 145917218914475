import { Image, ImageProps } from '@graphcommerce/image'
import { ProductListItemFragment } from '@graphcommerce/magento-product/Api/ProductListItem.gql'
import { ProductListPrice } from '@graphcommerce/magento-product/components/ProductListPrice/ProductListPrice'
import { useProductLink } from '@graphcommerce/magento-product/hooks/useProductLink'
import { extendableComponent } from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import { Box, Rating, styled, useEventCallback } from '@mui/material'
import PageLink from 'next/link'
import React from 'react'
import fastShipping from '../../../assets/images/fast-shipping.svg'
import shipping from '../../../assets/images/shipping.svg'

import { JsonLd } from '../../NextUi/JsonLd/JsonLd'
import { jsonLdProduct, jsonLdProductOffer } from '../JsonLdProduct/jsonLdProduct'

const { classes, selectors } = extendableComponent('ProductListItem', [
  'root',
  'item',
  'title',
  'titleContainer',
  'subtitle',
  'price',
  'overlayItems',
  'topLeft',
  'topRight',
  'bottomLeft',
  'bottomRight',
  'imageContainer',
  'placeholder',
  'image',
  'discount',
] as const)

export type OverlayAreaKeys = 'topLeft' | 'bottomLeft' | 'topRight' | 'bottomRight'

export type OverlayAreas = Partial<Record<OverlayAreaKeys, React.ReactNode>>

type StyleProps = {
  imageOnly?: boolean
}

type BaseProps = { children?: React.ReactNode } & StyleProps &
  OverlayAreas &
  ProductListItemFragment &
  Pick<ImageProps, 'loading' | 'sizes' | 'dontReportWronglySizedImages'>

export type ProductListItemProps = BaseProps & {
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>, item: ProductListItemFragment) => void
  columns?: number
}

const StyledImage = styled(Image)({})

export function ProductListItem(props: ProductListItemProps) {
  const {
    small_image,
    name,
    children,
    imageOnly = false,
    loading,
    sizes,
    dontReportWronglySizedImages,
    onClick,
    production_time,
    source,
    columns = 6,
    yotpo_average_rating,
    yotpo_review_count,
    moq,
    min_tier_min_unit_price,
    min_tier_max_unit_price,
  } = props

  const handleClick = useEventCallback((e: React.MouseEvent<HTMLAnchorElement>) =>
    onClick?.(e, props),
  )
  const numberOfDaysInWeek = 5
  const weeksBase = Math.floor((production_time ?? 0) / numberOfDaysInWeek)
  const weeksDisplay = production_time === numberOfDaysInWeek ? 'week' : 'weeks'
  const numberOfWeeksDisplay =
    (production_time ?? 0) % numberOfDaysInWeek > 0 ? `${weeksBase}-${weeksBase + 1}` : weeksBase
  const productionTimeDisplay =
    production_time && production_time < numberOfDaysInWeek
      ? `${production_time} days`
      : `${numberOfWeeksDisplay} ${weeksDisplay}`

  const shippingIconDisplay =
    production_time && production_time < numberOfDaysInWeek ? fastShipping : shipping

  const minimumOrderAndPriceDisplay =
    moq && min_tier_min_unit_price
      ? `${moq?.toLocaleString()} Min. $${
          parseFloat(min_tier_min_unit_price?.toFixed(2))?.toLocaleString() ?? 0
        }${!min_tier_max_unit_price ? ' each' : ''}${
          min_tier_max_unit_price
            ? `-$${parseFloat(min_tier_max_unit_price?.toFixed(2))?.toLocaleString() ?? 0} each`
            : ''
        }`
      : null

  const productLink = useProductLink(props)

  return (
    <div className='min-h-[310px] rounded-[5px]'>
      <PageLink
        href={productLink}
        passHref
        onClick={onClick ? handleClick : undefined}
        onKeyDown={() => (onClick ? handleClick : undefined)}
        className='block'
        role='button'
        tabIndex={0}
      >
        <div className='flex h-full w-full items-center justify-center'>
          <JsonLd
            item={{
              '@context': 'https://schema.org',
              ...jsonLdProduct(props),
              ...jsonLdProductOffer(props),
            }}
            identifier={props.uid}
          />
          {small_image ? (
            <div className='flex h-full w-full items-center justify-center'>
              <div className='flex w-full items-center justify-center rounded-[6px]'>
                <Box
                  component='a'
                  className=''
                  sx={{
                    overflow: 'hidden',
                    height: '100%',
                    width: '100%',
                    backgroundRepeat: 'no-repeat',
                    aspectRatio: '5/6',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '6px',
                    '@media (min-width: 960px)': {
                      minHeight: '200px',
                    },
                  }}
                >
                  <Image
                    src={`${small_image.url ?? ''}`}
                    unoptimized
                    layout='fill'
                    className='h-full w-auto object-cover'
                    sx={{
                      aspectRatio: '5/6',
                      borderRadius: '6px',
                    }}
                  />
                </Box>
              </div>
            </div>
          ) : (
            <Box
              sx={{
                gridArea: `1 / 1 / 4 / 4`,
                typography: 'caption',
                display: 'flex',
                textAlign: 'center',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'background.default',
                userSelect: 'none',
                borderRadius: '6px',
                aspectRatio: '5/6',
              }}
              className={`${classes.placeholder} ${classes.image}`}
            >
              <Trans id='No Image' />
            </Box>
          )}
        </div>

        {!imageOnly && (
          <div className='pt-0'>
            <div className='flex h-[25px] items-end justify-between'>
              {production_time && (
                <div className='flex items-center justify-center'>
                  <Image src={shippingIconDisplay} unoptimized height={12} className='mr-1' />
                  <span className='Type-Base-Regular whitespace-nowrap pt-1 text-70-grey'>
                    {productionTimeDisplay}
                  </span>
                </div>
              )}
              {yotpo_review_count && yotpo_review_count > 0 && (
                <PageLink
                  href={`${productLink}?scrollToReviews`}
                  passHref
                  onClick={onClick ? handleClick : undefined}
                  onKeyDown={() => (onClick ? handleClick : undefined)}
                  className='hover:ml-[2px] hover:scale-105'
                  role='button'
                  tabIndex={0}
                >
                  <Rating
                    name='size-small'
                    defaultValue={yotpo_average_rating ?? 0}
                    size='small'
                    readOnly
                    precision={0.5}
                    className='scale-90 align-bottom hover:scale-100'
                  />
                </PageLink>
              )}
            </div>
            <div className='mt-[8px]'>
              <h3 className='Type-XL-Medium'>{name}</h3>
            </div>

            {children}
            {minimumOrderAndPriceDisplay && (
              <div className='md:Type-Base-Regular Type-Base-Regular mt-[5px] text-70-grey md:mt-[10px]'>
                <span>{minimumOrderAndPriceDisplay}</span>
              </div>
            )}
          </div>
        )}
      </PageLink>
    </div>
  )
}

ProductListItem.selectors = { ...selectors, ...ProductListPrice.selectors }
