import { Image } from '@graphcommerce/image'
import { CartItemFragment } from '@graphcommerce/magento-cart-items/Api/CartItem.gql'
import { useProductLink } from '@graphcommerce/magento-product'
import { Money } from '@graphcommerce/magento-store'
import { filterNonNullableKeys } from '@graphcommerce/next-ui'
import { Box, Paper, SxProps, Theme } from '@mui/material'
import { default as Link, default as PageLink } from 'next/link'
import { useState } from 'react'
import { Icon } from '../../Layout/Icon'
import { RemoveItemFromCartFab } from '../RemoveItemFromCart/RemoveItemFromCartFab'

export type CartItemProps = CartItemFragment & {
  sx?: SxProps<Theme>
  children?: React.ReactNode
} & OwnerState

type OwnerState = { withOptions?: boolean }

export function MinicartItem(props: CartItemProps) {
  const { product, errors, uid, children, withOptions = true, prices } = props
  const { name } = product
  const productLink = useProductLink(product)

  const artworkFiles = localStorage.getItem('artworkFiles')?.split(',') ?? []
  const [localFiles, setLocalFiles] = useState(artworkFiles)

  const handleRemoveItem = (index: number) => {
    const localFilesCopy = [...localFiles]
    const newLocalFiles = localFilesCopy.filter((item, idx) => idx != index)
    setLocalFiles(newLocalFiles)
    localStorage.setItem('artworkFiles', newLocalFiles.toString())
  }

  return (
    <Paper
      elevation={3}
      className='my-[24px] grid items-center justify-center p-6'
      sx={{
        bgcolor: '#00000005',
      }}
    >
      <div className='grid max-w-full'>
        <div className='grid w-full grid-cols-[minmax(75vw,75vw)] items-center justify-center justify-items-center gap-2 sm:grid-cols-[minmax(45vw,45vw)] md:grid md:grid-cols-[minmax(309px,309px)_minmax(100px,100px)] md:gap-0'>
          <div className='flex w-full items-center justify-end md:hidden'>
            <RemoveItemFromCartFab uid={uid} />
          </div>
          <Link href={productLink} passHref className='Type-XL-Bold grid w-full text-tight-black'>
            <span className='Type-XL-Bold flex w-full break-normal text-center md:w-full md:text-left'>
              {name}
            </span>
          </Link>
          <div className='hidden w-full items-center justify-end md:flex'>
            <RemoveItemFromCartFab uid={uid} />
          </div>
        </div>
        <div className='flex w-full flex-col gap-[24px] pt-4 md:grid md:grid-cols-[30fr_70fr]'>
          <div className='flex w-auto flex-col items-center justify-center md:w-full md:max-w-full'>
            <PageLink href={productLink} passHref>
              {product?.thumbnail?.url && (
                <Box
                  component='a'
                  className=''
                  sx={{
                    overflow: 'hidden',
                    height: '100%',
                    width: '100%',
                    aspectRatio: '5/6',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '6px',
                    maxWidth: '240px',
                    maxHeight: '300px',
                    '@media (min-width: 640px)': {
                      maxWidth: '100%',
                    },
                  }}
                >
                  <Image
                    src={`${product.thumbnail?.url ?? ''}`}
                    alt={`${product.thumbnail?.label ?? ''}`}
                    unoptimized
                    height={60}
                    width={50}
                    layout='responsive'
                    className='h-full w-auto object-cover'
                    sx={{
                      aspectRatio: '5/6',
                      objectFit: 'cover',
                      height: '100%',
                      width: 'auto,',
                    }}
                  />
                </Box>
              )}
            </PageLink>
            <div className='w-full max-w-full lg:mt-2'>
              {localFiles.length > 0 &&
                localFiles?.map((item, index) => (
                  <div
                    key={item + index}
                    className='lg:Type-Large-Medium Type-Regular-Medium pointer-cursor block overflow-hidden text-ellipsis whitespace-nowrap text-templi-purple underline '
                  >
                    <Icon
                      name='disabled_by_default'
                      className='mr-2 cursor-pointer align-middle text-xl lg:text-3xl'
                      onClick={() => handleRemoveItem(index)}
                    />
                    {item}
                  </div>
                ))}
            </div>
          </div>

          <div className='flex w-full max-w-full flex-col items-center justify-center gap-2 md:items-start'>
            {children}
            <Box sx={{ gridArea: 'itemName', width: '100%' }}>
              {filterNonNullableKeys(errors).map((error) => (
                <Box sx={{ color: 'error.main', typography: 'caption' }} key={error.message}>
                  {error.message}
                </Box>
              ))}
            </Box>
            <hr className='my-1 w-full text-20-grey' />
            <div className='Type-Large-Regular flex w-full items-center justify-between'>
              <span>
                {(prices as any)?.setup_fee ? (
                  <Money {...(prices as any)?.setup_fee} />
                ) : (
                  <Money value={0} />
                )}{' '}
                setup
              </span>
              <span>
                Total <Money {...prices?.row_total} />
              </span>
            </div>
          </div>
        </div>
      </div>
    </Paper>
  )
}
