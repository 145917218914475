import { useCustomerSession } from '@graphcommerce/magento-customer'
import { i18n } from '@lingui/core'
import Link from 'next/link'
import { useContext } from 'react'
import { HighlightButton } from '../../Button/ButtonStyles'
import { globalContext } from '../../NextUi/globalContext'

export function CheckoutButton() {
  const session = useCustomerSession()
  const { setDrawer, subUserCan } = useContext(globalContext)

  if (!subUserCan('place-order')) {
    return null
  }

  if (!session.loggedIn) {
    return (
      <HighlightButton
        variant='contained'
        className='w-full bg-marigold'
        size='small'
        onClick={() => {
          setDrawer({ id: 'auth', params: { redirectAfterSignIn: '/checkout' } })
        }}
      >
        {i18n._(/* i18n */ 'Checkout')}
      </HighlightButton>
      // <Button
      //   className='block w-full'
      //   onClick={() => {
      //     setDrawer({ id: 'auth', params: { redirectAfterSignIn: '/checkout' } })
      //   }}
      //   label={i18n._(/* i18n */ 'Checkout')}
      // />
    )
  }

  return (
    <Link href='/checkout'>
      <HighlightButton variant='contained' className='w-full bg-marigold' size='small'>
        {i18n._(/* i18n */ 'Checkout')}
      </HighlightButton>
    </Link>
  )
}
