import type { ProductImage } from '@graphcommerce/graphql-mesh'
import { JsonLdProductFragment } from '@graphcommerce/magento-product/components/JsonLdProduct/JsonLdProduct.gql'
import { JsonLdProductOfferFragment } from '@graphcommerce/magento-product/components/JsonLdProduct/JsonLdProductOffer.gql'
import type { Product } from 'schema-dts'
import { JsonLdProductOfferAdditionalFragment } from './JsonLdProductOfferAdditional.gql'

export function jsonLdProduct(props: JsonLdProductFragment): Product {
  const { name, sku, media_gallery, categories, description } = props

  return {
    '@type': 'Product',
    name: name ?? undefined,
    sku: sku ?? undefined,
    image: media_gallery
      ? media_gallery?.map((img) => (img as ProductImage)?.url ?? '')
      : undefined,
    category: categories?.[0]?.name ?? undefined,
    description: description?.html
      ? (description.html ?? '').replace(/(<([^>]+)>)/gi, '')
      : undefined,
  }
}

/** @see https://developers.google.com/search/docs/advanced/structured-data/product */
export function jsonLdProductOffer(
  props: JsonLdProductOfferFragment & JsonLdProductOfferAdditionalFragment,
): Pick<Product, 'offers'> {
  const { min_tier_price, max_tier_price, price_range } = props

  let lowPrice = min_tier_price ?? undefined
  let highPrice = max_tier_price ?? undefined

  return {
    offers: {
      '@type': 'AggregateOffer',
      itemCondition: 'https://schema.org/NewCondition',
      offerCount: 1,
      priceCurrency: price_range.minimum_price.regular_price.currency ?? undefined,
      highPrice,
      lowPrice,
    },
  }
}
