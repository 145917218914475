import { CartItemProps } from '@graphcommerce/magento-cart-items'
import { QuantityUpdate } from '../../4Over/QuantityUpdate/QuantityUpdate'
import { MinicartItem } from '../../CartItems/CartItem/MinicartItem'
import { OptionsList } from '../../ProductConfigurable/ConfigurableCartItem/OptionList'
import { SimpleCartItemOptionsFragment } from '../SimpleCartItemOptions.gql'

export function SimpleMinicartItem(props: SimpleCartItemOptionsFragment & CartItemProps) {
  const { customizable_options, product, children, ...cartItemProps } = props

  return (
    <MinicartItem
      {...cartItemProps}
      product={{
        ...product,
        name: product.name ?? product?.name,
        thumbnail: !product?.thumbnail?.url?.indexOf('placeholder')
          ? product?.thumbnail
          : product.thumbnail,
      }}
      withOptions
    >
      <OptionsList configurable_customizable={customizable_options} />
      {product?.sku?.includes('4over') && <QuantityUpdate {...props} className='w-full' />}
      {children}
    </MinicartItem>
  )
}
