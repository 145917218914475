import Head from 'next/head'
import { safeJsonLdReplacer } from '@graphcommerce/next-ui/JsonLd/safeJsonLdReplacer'

export * as SchemaDts from 'schema-dts'

export function JsonLd<T extends { '@type': string }>(props: {
  item: T & { '@context': 'https://schema.org' },
  identifier?: string
}) {
  // Added identifier in order to avoid overriding the script, so that we can have multiple schema scripts
  const { item, identifier } = props

  return (
    <Head>
      <script
        key={identifier || 'jsonld'}
        type='application/ld+json'
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: JSON.stringify(item, safeJsonLdReplacer) }}
      />
    </Head>
  )
}
