import { Skeleton } from '@mui/material'

export function ProductCardSkeleton() {
  return (
    <div className='flex aspect-[5/6] w-full min-w-[120px] flex-col items-center justify-center object-cover md:min-w-[150px] lg:min-w-[180px]'>
      <Skeleton
        variant='rounded'
        sx={{
          height: '100%',
          width: '100%',
          minHeight: '200px',
          borderRadius: '6px',
          objectFit: 'cover',
        }}
        animation='pulse'
      />
      <Skeleton
        variant='text'
        sx={{ fontSize: '1rem', width: '100%', marginTop: '4px' }}
        animation='pulse'
      />

      <Skeleton variant='text' sx={{ fontSize: '0.5rem', width: '100%' }} animation='pulse' />
      <Skeleton variant='text' sx={{ fontSize: '0.5rem', width: '100%' }} animation='pulse' />
    </div>
  )
}
