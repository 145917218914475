import { ProductPageMetaFragment } from '@graphcommerce/magento-product/components/ProductPageMeta/ProductPageMeta.gql'
import { IfConfig, PluginProps } from '@graphcommerce/next-config'
import { useMemoObject } from '@graphcommerce/next-ui'
import { useEffect } from 'react'
import { JsonLdProductOfferAdditionalFragment } from '../../components/Product/JsonLdProduct/JsonLdProductOfferAdditional.gql'

export const component = 'ProductPageMeta'
export const exported = '@graphcommerce/magento-product'
export const ifConfig: IfConfig = 'klaviyoPublicKey'

/** When a product is added to the Cart, send a Google Analytics event */
function ViewedProduct(
  props: PluginProps<ProductPageMetaFragment & JsonLdProductOfferAdditionalFragment>,
) {
  const { Prev, name, sku, categories, uid, url_key, media_gallery, min_tier_price } = props

  const productUrl = new URL(`${import.meta.graphCommerce.canonicalBaseUrl}p/${url_key}`)

  var _learnq = _learnq || []
  const viewItem = useMemoObject({
    ProductName: name,
    ProductID: uid,
    SKU: sku,
    Categories: categories?.map((category) => category?.name),
    ImageURL: media_gallery?.at(0)?.url,
    URL: productUrl.href,
    Price: min_tier_price ?? 0,
  })

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  useEffect(() => {
    globalThis._learnq?.push(['track', 'Viewed Product', viewItem])
  }, [viewItem])

  return <Prev {...props} />
}

export const Plugin = ViewedProduct
