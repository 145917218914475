import { ProductPageMetaFragment } from '@graphcommerce/magento-product/components/ProductPageMeta/ProductPageMeta.gql'
import { IfConfig, PluginProps } from '@graphcommerce/next-config'
import { useMemoObject } from '@graphcommerce/next-ui'
import { useEffect } from 'react'

export const component = 'ProductPageMeta'
export const exported = '@graphcommerce/magento-product'
export const ifConfig: IfConfig = 'fbPixelId'

/** When a product is added to the Cart, send a Google Analytics event */
function FbViewContent(props: PluginProps<ProductPageMetaFragment>) {
  const { Prev, price_range, name, sku, categories, meta_description } = props

  const viewItem = useMemoObject({
    content_ids: [sku],
    content_category: categories?.[2]?.name ?? undefined,
    content_name: name,
    content_type: 'product',
    contents: meta_description,
    currency: price_range.minimum_price.final_price.currency,
    value: price_range.minimum_price.final_price.value,
  })

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  useEffect(() => globalThis.fbq?.('track', 'ViewContent', viewItem), [viewItem])

  return <Prev {...props} />
}

export const Plugin = FbViewContent
