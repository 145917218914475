import { CartItemProps } from '@graphcommerce/magento-cart-items'
import { ConfigurableCartItemFragment } from '@graphcommerce/magento-product-configurable/ConfigurableCartItem/ConfigurableCartItem.gql'
import { ConfigurableCartItemOptionsFragment } from '../../CartItems/CartItem/CartItem.gql'
import { MinicartItem } from '../../CartItems/CartItem/MinicartItem'
import { QuantityUpdate } from '../../CartItems/CartItem/QuantityUpdate/QuantityUpdate'
import { OptionsList } from './OptionList'

export function ConfigurableMinicartItem(
  props: ConfigurableCartItemFragment & {
    configured_variant: ConfigurableCartItemOptionsFragment['configured_variant']
  } & CartItemProps,
) {
  const {
    configurable_options,
    configurable_customizable,
    configured_variant,
    product,
    children,
    ...cartItemProps
  } = props
  return (
    <MinicartItem
      {...cartItemProps}
      product={{
        ...product,
        name: product.name ?? configured_variant?.name,
        thumbnail: !configured_variant?.thumbnail?.url?.indexOf('placeholder')
          ? configured_variant?.thumbnail
          : product.thumbnail,
      }}
      withOptions
    >
      <OptionsList
        configurable_options={configurable_options}
        configurable_customizable={configurable_customizable}
      />
      <QuantityUpdate {...props} className='w-full' />
      {children}
    </MinicartItem>
  )
}
