import { useCallback, useEffect, useState } from 'react'

export interface WindowSizeDetails {
  width: number | undefined
  height: number | undefined
  isMobile: boolean
  isTablet?: boolean | undefined
  isDesktop?: boolean | undefined
}

// Hook
const useWindowSize = (props = { mobileMaxWidth: 768, maxTabletWidth: 992 }) => {
  const { mobileMaxWidth, maxTabletWidth } = props

  const [windowSize, setWindowSize] = useState<WindowSizeDetails>({
    width: undefined,
    height: undefined,
    isMobile: false,
  })

  const handleResize = useCallback(() => {
    if (typeof window === 'undefined') {
      return
    }
    // Set window width/height to state
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
      isMobile: !!mobileMaxWidth && window.innerWidth <= mobileMaxWidth,
      isTablet: window.innerWidth <= maxTabletWidth && window.innerWidth > mobileMaxWidth,
    })
  }, [mobileMaxWidth, maxTabletWidth])

  useEffect(() => {
    if (typeof window === 'undefined') {
      return () => {}
    }
    // only execute all the code below in client side
    // Add event listener
    window.addEventListener('resize', handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, [handleResize])
  return windowSize
}

export default useWindowSize
